import { Row, Col, Card, Spin, Alert, Button } from 'antd';
import { useEffect, useCallback, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import APIService from '@/services/API';
import logo from '@/assets/img/l_4.png';
import { useAuth } from '@/hooks/auth.hook';
import { HttpError } from '@/interfaces/api.interface';

const VerifyScreen = () => {
  const { token: userToken } = useAuth();
  if (userToken) {
    return <Navigate to="/dashboard" />;
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState<HttpError | undefined>({
    className: '',
    code: 0,
    message: '',
    name: '',
    data: [],
  });

  const sendToken = useCallback(async (data: string) => {
    setLoading(true);

    const response = await APIService.verifyUser(data);
    if (response.ok) {
      setMessage({
        className: 'Success',
        code: 200,
        message: response.data?.message || '',
        name: 'Success',
        data: [],
      });
    } else {
      setMessage(response.data);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (token) sendToken(token);
  }, []);

  const showCustomError = (error: HttpError | undefined) =>
    error?.code !== 200
      ? `${error?.message}, ${t('general.errorCode')}: ${error?.code}`
      : `${error?.message}`;

  return (
    <div className="login-screen">
      <div className="cover"></div>
      <Row className="wrapper" justify="center" align="middle">
        <Col xxl={8} xl={8} lg={12} md={18} sm={18} xs={23}>
          <Card>
            <Row justify="center">
              <Col className="logo">
                <img src={logo} alt="Real state" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {loading ? (
                  <Spin tip={t('verify.verifying')} size="large" />
                ) : (
                  <Alert
                    type={message?.code !== 200 ? 'error' : 'success'}
                    message={
                      message?.code !== 200 ? t('general.error') : t('verify.title')
                    }
                    description={showCustomError(message)}
                    showIcon
                  />
                )}
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <Button
                  type="link"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  {t('forgot.backLogin')}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default VerifyScreen;
